.no_top {
    margin-top: 0;
    padding-top: 0;
}

.header {
    color: #FFFFFF !important;
    font-weight: strong;
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(1em + 1vw);
    padding-top: 5%;
    font-style: italic;
}

.header_text {
    color: #FFFFFF !important;
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(0.7em + 0.7vw);
    padding-top: 1%;
    font-style: italic;
}

.header_footer {
    color: #FFFFFF !important;
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(0.5em + 0.5vw);
    padding-bottom: 5%;
}

.sub_header {
    font-weight: strong;
    font-size: calc(0.9em + 0.9vw);;
}


.header_normal {
    font-weight: strong;
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(1em + 1vw);
    padding-top: 5%;
}

.header_text_normal {
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(0.7em + 0.7vw);
    padding-top: 1%;
    font-style: italic;
}

.sub_header_normal {
    line-height: 1.58;
    letter-spacing: -.003em;
    font-size: calc(0.75em + 0.75vw);
    margin-top: 1%
}

.card_toc {
    width: 30%
}

@media screen and (max-width: 768px) {
    .header {
        font-size: 25px;
    }

    .header_text {
        font-size: 18px;
    }

    .header_footer {
        font-size: 15px;
    }

    .header_normal {
        font-size: 25px;
    }

    .header_text_normal {
        font-size: 18px;
    }

    .sub_header_normal {
        font-size: 18px;
    }

    .card_toc {
        width: 50%
    }
}

a.header_link:link {
    color: white;
}
  
a.header_link:visited {
    color: white;
}
  
a.header_link:hover {
    color: #fbff00 !important;
}
  
a.header_link:active {
    color: white;
}

.footer_text {
    color: white;
    font-size: 15px;
}

.something_wrong {
    font-size: calc(1em + 1vw);
    color: red !important;
    text-align: center;
}

.italic_text {
    font-style: italic;
}
